import React from 'react';
import './HeroSection.css';

const HeroSection = () => {
  return (
    <section id="hero" className="hero">
      <h1>Welcome to TripBooking</h1>
      <p>Find and book your travels easily!</p>
      <div className="hero-buttons">
        <a href="https://play.google.com/store/apps/details?id=co.median.android.plrdnk&pcampaignid=web_share" className="btn-primary" target="_blank" rel="noopener noreferrer">Google Play</a>
        <a href="https://apps.apple.com/us/app/tripbooking/id1234567890" className="btn-secondary" target="_blank" rel="noopener noreferrer">App Store</a>
      </div>
    </section>
  );
};

export default HeroSection;