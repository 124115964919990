import React from 'react';
import './AboutSection.css';
const AboutSection = () => {
  return (
    <section id="about" className="about">
      <div className="about-container">
        <div className="about-image">
          <img src="Aa.png" alt="About TripBooking" />
        </div>
        <div className="about-text">
          <h2>About TripBooking</h2>
          <p>
            TripBooking is the ultimate app for organizing your travels in a simple and efficient way.</p>
          <p>
            Whether you're planning a weekend getaway or a trip around the world, </p>
            <p>our app provides advanced features to manage your bookings,</p>
          
          <p>
            find the best deals, and receive personalized recommendations.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;