import React, { useState } from 'react';
import './NavBar.css';

const NavBar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">TripBooking</div>
      <div className="navbar-menu">
        <div className="menu-toggle" onClick={toggleMenu}>
          <div className="menu-icon"></div>
          <div className="menu-icon"></div>
          <div className="menu-icon"></div>
        </div>
        {isMenuOpen && (
          <div className="dropdown-menu">
            <a href="#hero" className="dropdown-item">Home</a>
            <a href="#about" className="dropdown-item">About</a>
            <a href="#features" className="dropdown-item">Features</a>
            <a href="#reviews" className="dropdown-item">Reviews</a>
            <a href="#contact" className="dropdown-item">Contact</a>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;