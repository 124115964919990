import React from 'react';
import './FeaturesSection.css';

const features = [
  "Easy and fast booking",
  "Exclusive offers for users",
  "24/7 customer support",
  "Secure payment options",
  "Live flight tracking",
];

const FeaturesSection = () => {
  return (
    <section id="features" className="features">
      <h2>Features</h2>
      <div className="features-content">
        <div className="features-list">
          {features.map((feature, index) => (
            <div key={index} className="feature-item">
              <div className="feature-icon-container">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#EB662B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
              <p className="feature-text">{feature}</p>
            </div>
          ))}
        </div>
        <div className="features-image">
          <img src="Cc.png" alt="Animation" className="moving-image" />
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;