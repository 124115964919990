import React from 'react';
import './ReviewsSection.css';

const reviews = [
  {
    id: 1,
    name: "Alice Dupont",
    comment: "TripnBooking has made my travels so much easier!",
    rating: 5
  },
  {
    id: 2,
    name: "Jean Martin",
    comment: "Great app with practical features.",
    rating: 4
  },
  {
    id: 3,
    name: "Marie Legrand",
    comment: "Easy to use and very efficient for booking.",
    rating: 5
  },
  {
    id: 4,
    name: "Pierre Durand",
    comment: "Customer support is very responsive and helpful.",
    rating: 4
  }
];

const ReviewsSection = () => {
  return (
    <section id="reviews" className="reviews">
      <h2> Reviews</h2>
      <div className="reviews-list">
        {reviews.map((review) => (
          <div key={review.id} className="review">
            <h3>{review.name}</h3>
            <p>{review.comment}</p>
            <div className="stars">
              {Array.from({ length: review.rating }).map((_, index) => (
                <span key={index} className="star">★</span>
              ))}
              {Array.from({ length: 5 - review.rating }).map((_, index) => (
                <span key={index} className="star empty">★</span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ReviewsSection;